import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';

export const obtainTTLockAccess = (vm, reservationId, room, dateFrom, dateTo) => {
  if (parseInt(process.env.VUE_APP_DEBUG, 10) === 1) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  console.log('Room', room);

  return api(vm, endpoints.ENDPOINT_TTLOCK_ISSUE, methods.POST_TTLOCK_DATA)(reservationId, room.id, dateFrom, dateTo);
};
