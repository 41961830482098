<template>
  <div class="room">
    <h1 v-if="type === 'lost-card'">{{ $t('issue_card.dispatch_card.title_lost_card') }}</h1>
    <h1 v-else>{{ $t('issue_card.dispatch_card.title') }}</h1>

    <p class="info-block">
      {{ issueCardText }}
    </p>

    <ProfileDetail />
    <LanguageSelect absolutePosition />

    <div class="room-list">
      <RoomItem v-for="room in rooms"
                :key="room.id"
                :room="room"
      >
        <template v-slot:dispatchedCards v-if="type !== 'lost-card'">
          {{ $t('issue_card.dispatch_card.dispatched_count') }}:
          {{ room.dispatched_cards }} / {{ room.guests }}
        </template>
        <template v-slot:action>
          <ButtonInput type="button"
            v-if="cardCanBeIssued(room)"
            @click="() => issueCardForReservation(room)"
            :loading="loading === room.id"
            :disabled="disabledButton[room.id] || loading"
          >
            <KeyCardSvg />
            <span v-if="type === 'lost-card'">{{ $t('issue_card.dispatch_card.action.dispatch_lost_card') }}</span>
            <span v-else>{{ $t('issue_card.dispatch_card.action.dispatch_card') }}</span>
          </ButtonInput>
          <div v-else-if="type === 'lost-card'" class="issue-fail-info">
            {{ $t('issue_card.dispatch_card.spare_card_cant_issue') }}
          </div>
          <div v-else class="issue-fail-info">
            {{ $t('issue_card.dispatch_card.card_issue_fail') }}<br />{{ room.date_from }} - {{ room.date_to }}
          </div>
        </template>
      </RoomItem>
    </div>

    <WholepageLoader v-if="loading">
      <h1 class='key-issue' v-if="loadingState === 'key'">{{ $t('issue_card.loader.issuing_key') }}</h1>
      <h1 class='checking-in' v-if="loadingState === 'checkin'">{{ $t('issue_card.loader.checking_in') }}</h1>
    </WholepageLoader>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import LanguageSelect from '@/components/LanguageSelect/index';
import RoomItem from '@/components/RoomList/RoomItem';
import {
  GET_RESERVATION_DETAIL,
  GET_ROOMS,
  DISPATCH_CARD_PROPER,
  DISPATCH_CARD_SPARE,
  SAVE_TTLOCK_DATA,
} from '@/store/reservation';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import ProfileDetail from '@/components/ProfileDetail';
import { issueCard, lostKeyHandler, cardHelperErrorHandle } from '@/helpers/cardHelper';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import { TYPE_PROPER_CARD, TYPE_SPARE_CARD } from '@/api/endpoints/dispense';
import api from '@/api/handler';
import KeyCardSvg from '@/assets/images/icons/keycard.svg';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import flashMessage from '@/helpers/flashMessage';
import {
  readRFCardData,
} from '@/helpers/encoderHelper';
import * as cisaHelper from '@/helpers/mobileKeys/cisaHelper';
import * as saltoEncoderHelper from '@/helpers/encoders/saltoEncoderHelper';
import * as AssaAbloyVingCardEncoderHelper from '@/helpers/encoders/assaAbloyVingCardEncoderHelper';
import * as AssaAbloyTesaCardEncoderHelper from '@/helpers/encoders/assaAbloyTesaCardEncoderHelper';
import * as onityEncoderHelper from '@/helpers/encoders/onityEncoderHelper';
import * as dormakabaEncoderHelper from '@/helpers/encoders/dormakabaEncoderHelper';
import * as exceptionCode from '@/constants/exceptionsCode';
import { FALLBACK_LANGUAGE, ISO_CODE_TO_LANGUAGE } from '@/constants/trans';
import { logEvent } from '@/helpers/analytics';
import * as keySystemType from '@/constants/keySystemType';
import { obtainTTLockAccess } from '@/helpers/encoders/ttLockHelper';
import WholepageLoader from '@/components/WholepageLoader';

export default {
  name: 'IssueCard',
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  components: {
    LanguageSelect,
    RoomItem,
    ProfileDetail,
    KeyCardSvg,
    ButtonInput,
    WholepageLoader,
  },
  data() {
    return {
      loading: null,
      loadingState: 'checkin',
      type: this.$route.params.type,
      cisaPassCode: null,
      cisaUserCode: null,
    };
  },
  computed: {
    ...mapGetters({
      reservation: GET_RESERVATION_DETAIL,
      rooms: GET_ROOMS,
      property: GET_PROPERTY_DETAIL,
    }),
    keyService() {
      return _.get(this.property, 'key_service');
    },
    propertySettings() {
      return _.get(this.property, 'settings') || [];
    },
    disabledButton() {
      const roomDisabled = [];
      _.each(this.rooms, (room) => {
        roomDisabled[room.id] = parseInt(room.spare_cards, 10) !== 0;
        if (this.type !== 'lost-card') {
          roomDisabled[room.id] = parseInt(room.dispatched_cards, 10) === parseInt(room.guests, 10);
        }
      });
      return roomDisabled;
    },
    issueCardText() {
      const type = this.type === 'lost-card' ? 'issueSpareCardRoom' : 'issueCardRoom';
      const fallback = _.get(this.property, `settings.${type}.${ISO_CODE_TO_LANGUAGE[FALLBACK_LANGUAGE]}`);
      return _.get(this.property, `settings.${type}.${ISO_CODE_TO_LANGUAGE[this.$i18n.locale] || this.$i18n.locale}`) || fallback;
    },
  },
  methods: {
    async issueCardForReservation(room) {
      const roomId = room.id;
      const roomCode = room.code;

      this.loading = roomId;

      const reservation = this.reservation;
      const dateFrom = new moment((room.date_from || reservation.date_from) + ' ' + reservation.checkin_time);
      const dateTo = new moment((room.date_to || reservation.date_to) + ' ' + reservation.checkout_time);

      const cardType = this.type === 'lost-card' ? TYPE_SPARE_CARD : TYPE_PROPER_CARD;

      const controllerWebsocketServer = 'ws://' + _.get(this.property, 'settings.controller_websocket_server');
      const kioskSettings = _.get(this.property, 'settings');
      const encoderNo = _.get(kioskSettings, 'encoder_no');

      logEvent('onIssueCardIssueRoomCardClicked');

      if (cardType === TYPE_SPARE_CARD) {
        await lostKeyHandler(roomCode, encoderNo, controllerWebsocketServer, this.lostKeyHelper);
      }

      if (cardType === TYPE_SPARE_CARD) {
        this.loadingState = 'key';
        api(this, endpoints.ENDPOINT_DISPENSE, methods.POST_DISPENSE_CARD)(cardType, roomId, reservation.id)
          .then(({metadata}) => {
            if (metadata && metadata.success) {
              if (this.keyService === keySystemType.TTLOCK) {
                return this.operateTTLock(dateFrom, dateTo, room, true)
                  .then((data) => {
                    if (metadata && metadata.success) {
                      this.$store.dispatch(SAVE_TTLOCK_DATA, data.data || {});
                      this.$store.dispatch(DISPATCH_CARD_SPARE, roomId);
                      this.$router.push({
                        name: 'issue-card-lost-card-success',
                        params: {
                          id: roomId,
                        },
                      });
                    } else {
                      this.loading = null;
                      this.loadingState = 'checkin';
                      flashMessage(this.$store, this.$t('issue_card.alert.error.ttlock_error'), 'danger');
                    }
                  })
                  .catch((err) => {
                    this.loading = null;
                    this.loadingState = 'checkin';
                    const responseData = _.get(err, 'data') || '';
                    const message = cardHelperErrorHandle(responseData) || this.$t('issue_card.alert.error.general_error');
                    flashMessage(this.$store, this.$t(message), 'danger');
                  });
              }

              issueCard(dateFrom, dateTo, room, encoderNo, kioskSettings, controllerWebsocketServer, this.encodeCard, this.postEncodeCallback, cardType === TYPE_SPARE_CARD)
                .then(() => {
                  this.$store.dispatch(DISPATCH_CARD_SPARE, roomId);
                  this.$router.push({
                    name: 'issue-card-lost-card-success',
                    params: {
                      id: roomId,
                    },
                  });
                })
                .catch((err) => {
                  this.loading = null;
                  this.loadingState = 'checkin';
                  const responseData = _.get(err, 'data') || '';
                  const message = cardHelperErrorHandle(responseData) || this.$t('issue_card.alert.error.general_error');
                  flashMessage(this.$store, this.$t(message), 'danger');
                });

            } else {
              this.loading = null;
              this.loadingState = 'checkin';
              flashMessage(this.$store, this.$t('issue_card.alert.error.general_error'), 'danger');
            }
          })
          .catch((err) => {
            this.loading = null;
            this.loadingState = 'checkin';
            const responseCode = parseInt(_.get(err, 'response.data.responseCode'), 10);
            const responseMetadataCode = parseInt(_.get(err, 'response.data.responseBody.metadata.data.code'), 10);
            if (responseCode === 400) {
              if ([exceptionCode.CHECKIN_MISSING_GUEST, exceptionCode.CHECKIN_MISSING_ROOM, exceptionCode.CHECKIN_ROOM_NOT_READY].indexOf(responseMetadataCode) >= 0) {
                this.$router.push(`/no-rooms-reservation/${roomId}`);
                return;
              }
              flashMessage(this.$store, this.$t('issue_card.alert.error.general_error'), 'danger');
            } else {
              flashMessage(this.$store, this.$t('issue_card.alert.error.general_error'), 'danger');
            }
          });

      } else {
        api(this, endpoints.ENDPOINT_DISPENSE, methods.POST_DISPENSE_CARD)(cardType, roomId, reservation.id)
          .then(({metadata}) => {
            this.loadingState = 'key';
            console.log('Metadata', metadata);
            if (metadata && metadata.success) {
              if (this.keyService === keySystemType.TTLOCK) {
                return this.operateTTLock(dateFrom, dateTo, room, false)
                  .then((data) => {
                    if (metadata && metadata.success) {
                      this.$store.dispatch(SAVE_TTLOCK_DATA, data.data || {});
                      this.$store.dispatch(DISPATCH_CARD_PROPER, roomId);
                      this.$router.push(`/room/directions/${roomId}`);
                    } else {
                      this.loading = null;
                      this.loadingState = 'checkin';
                      flashMessage(this.$store, this.$t('issue_card.alert.error.ttlock_error'), 'danger');
                    }
                  })
                  .catch((err) => {
                    this.loading = null;
                    this.loadingState = 'checkin';
                    const responseData = _.get(err, 'data') || '';
                    const message = cardHelperErrorHandle(responseData) || this.$t('issue_card.alert.error.general_error');
                    flashMessage(this.$store, this.$t(message), 'danger');
                  });
              }

              issueCard(dateFrom, dateTo, room, encoderNo, kioskSettings, controllerWebsocketServer, this.encodeCard, this.postEncodeCallback, cardType === TYPE_SPARE_CARD)
                .then(() => {
                  this.$store.dispatch(DISPATCH_CARD_PROPER, roomId);
                  this.$router.push(`/room/directions/${roomId}`);
                })
                .catch((err) => {
                  this.loading = null;
                  this.loadingState = 'checkin';
                  const responseData = _.get(err, 'data') || '';
                  const message = cardHelperErrorHandle(responseData) || this.$t('issue_card.alert.error.general_error');
                  flashMessage(this.$store, this.$t(message), 'danger');
                });

            } else {
              this.loading = null;
              this.loadingState = 'checkin';
              flashMessage(this.$store, this.$t('issue_card.alert.error.general_error_checkin'), 'danger');
            }
          })
          .catch((err) => {
            this.loading = null;
            this.loadingState = 'checkin';
            const responseCode = parseInt(_.get(err, 'response.data.responseCode'), 10);
            const responseMetadataCode = parseInt(_.get(err, 'response.data.responseBody.metadata.data.code'), 10);
            if (responseCode === 400) {
              if ([exceptionCode.CHECKIN_MISSING_GUEST, exceptionCode.CHECKIN_MISSING_ROOM, exceptionCode.CHECKIN_ROOM_NOT_READY].indexOf(responseMetadataCode) >= 0) {
                this.$router.push(`/no-rooms-reservation/${roomId}`);
                return;
              }
              flashMessage(this.$store, this.$t('issue_card.alert.error.general_error_checkin'), 'danger');
            } else {
              flashMessage(this.$store, this.$t('issue_card.alert.error.general_error_checkin'), 'danger');
            }
          });
      }
    },
    encodeCard(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) {
      switch (this.keyService) {
      case keySystemType.ASSA_ABLOY_VING_CARD:
        return AssaAbloyVingCardEncoderHelper.encodeAssaAbloyCard(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
      case keySystemType.ASSA_ABLOY_TESA:
        return AssaAbloyTesaCardEncoderHelper.encodeTesaCard(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
      case keySystemType.CISA:
        return readRFCardData(wsClient);
      case keySystemType.SALTO:
        return saltoEncoderHelper.encodeSaltoCard(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
      case keySystemType.ONITY:
        return onityEncoderHelper.prepareOnityCardCode(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
      case keySystemType.DORMAKABA:
        return dormakabaEncoderHelper.encodeDormakabaCard(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
      case keySystemType.NONE:
        return true;
      }

      flashMessage(this.$store, this.$t('issue_card.alert.error.general_error'), 'danger');
      return null;
    },
    async postEncodeCallback(wsClient, encoderData, dateFrom, dateTo, room) {
      switch (this.keyService) {
      case keySystemType.ASSA_ABLOY_VING_CARD:
        return null;
      case keySystemType.ASSA_ABLOY_TESA:
        return null;
      case keySystemType.CISA:
        this.cisaPassCode = Math.round(Math.random()*1000000);
        this.cisaUserCode = 'MS' + Math.round(Math.random()*100000);
        return cisaHelper.createGuest(wsClient, this.propertySettings, encoderData, dateFrom, dateTo, room, this.cisaUserCode, this.cisaPassCode);
      case keySystemType.SALTO:
        return null;
      case keySystemType.ONITY:
        return onityEncoderHelper.encodeOnityCard(wsClient, encoderData);
      case keySystemType.DORMAKABA:
        return null;
      case keySystemType.NONE:
        return null;
      default:
        return null;
      }
    },
    lostKeyHelper(wsClient, encoderNo, roomCode) {
      switch (this.keyService) {
      case keySystemType.ASSA_ABLOY_VING_CARD:
        return AssaAbloyVingCardEncoderHelper.lostKeyAssaAbloy(wsClient, encoderNo, this.propertySettings, roomCode);
      case keySystemType.ASSA_ABLOY_TESA:
        return AssaAbloyTesaCardEncoderHelper.lostKeyTesa(wsClient, this.propertySettings, roomCode);
      case keySystemType.CISA:
        return cisaHelper.lostkeyCisa(wsClient, this.propertySettings, roomCode);
      case keySystemType.SALTO:
        return saltoEncoderHelper.lostKeySalto(wsClient, encoderNo, this.propertySettings, roomCode);
      case keySystemType.ONITY:
        return onityEncoderHelper.lostKeyOnity(wsClient, this.propertySettings, roomCode);
      case keySystemType.DORMAKABA:
        return dormakabaEncoderHelper.lostKeyDormakaba(wsClient, this.propertySettings, roomCode);
      }

      flashMessage(this.$store, this.$t('issue_card.alert.error.general_error'), 'danger');
      return null;
    },
    cardCanBeIssued(room) {
      if (this.type === 'lost-card') {
        return room.dispatched_cards > 0;
      }

      const now = moment();
      const dateFrom = moment(room.date_from + ' 00:00:00');
      const dateTo = moment(room.date_to + ' 23:59:59');

      return dateFrom <= now && dateTo >= now;
    },
    operateTTLock(dateFrom, dateTo, room) {
      return obtainTTLockAccess(this, this.reservation.id, room, dateFrom, dateTo);
    },
  },
};
</script>

<style lang="scss">
  .room {
    width: calc(100% - 4rem);
    text-align: center;
    max-height: calc(100vh - 2rem);
    margin-top: 2rem;
  }

  .room-list {
    margin: auto;
    display: flex;
    justify-content: center;
    max-width: calc(100vw - 4rem);
    flex-wrap: wrap;
  }

  .issue-fail-info {
    text-align: center;
    line-height: 1.5rem;
    color: $text-color;
    padding: .3rem 1rem;
  }

  .info-block {
    max-width: 30rem;
    padding: 0 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
</style>
