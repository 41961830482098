<template>
  <div class="wholepage-loading">
    <slot></slot>
    <MyStayLogoLoader />
  </div>
</template>

<script>
import MyStayLogoLoader from '@/assets/images/myStayLogoLoader.svg';

export default {
  name: 'WholepageLoader',
  components: {
    MyStayLogoLoader,
  },
};
</script>

<style lang="scss" scoped>
  .wholepage-loading {
    width: 100%;
    height: 100%;
    background-color: $body-bg;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      max-width: 20rem;
    }
  }
</style>